<template>
  <div class="login_page">
    <v-header :params="{ title, leftIcon: true }"></v-header>
    <div class="content">
      <van-field class="inputMessage" v-model="username" label="手机号" clearable placeholder="请输入手机号" maxlength="11" oninput="value=value.replace(/[^\d]/g,'')" />
      <van-field class="inputMessage" v-model="smsVerifyCode" center clearable label="短信验证码" placeholder="请输入短信验证码" maxlength="4">
        <template #button>
          <van-button size="small" type="primary" @click="sendCode">
            <span v-if="!sendDisabled">发送验证码</span>
            <span v-else class="smsVerifyCode">{{time+'秒后重新获取'}}</span>
          </van-button>
        </template>
      </van-field>
      <van-field class="inputMessage" v-model="userPwd" type="password" label="密码" clearable placeholder="请输入密码" />
      <van-field class="inputMessage" v-model="userPwd2" type="password" label="确认密码" clearable placeholder="请输入确认密码" />
      <div style="margin: 16px;">
        <van-button round block type="info" @click="onSubmit">注册</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import vHeader from "@/components/header.vue";
import validator from '@/assets/js/util/validator';
import isWeixin from '@/utils/utils.js'

export default {
  components: {
    vHeader,
  },
  data() {
    return {
      title: this.$route.meta.title,
      appId: 'webhaojingchouh5',
      userType: 'PHONE',  //  USERNAME, EMAIL, PHONE, SMS, WECHAT_OPENID, WX_MINP_OPENID, APPLE_ID
      username: '',  // 手机号码 或 邮箱
      smsVerifyCode: '',  // 验证码
      userPwd: '', // 密码
      userPwd2: '',
      sendDisabled: false,
      time: 60,
    };
  },
  mounted:function(){
    //判断网页是什么打开
    let isWeiXin = isWeixin()
    if(isWeiXin){
      this.showHeader = false
    }
    if(this.showHeader==false){
      document.title = ''
    }
  },
  methods: {
    async onSubmit() {
      if (!validator.isMobile(this.username, "手机号码格式有误")) {
        return
      }
      if (!this.userPwd) {
        this.$toast('请输入密码')
        return
      }
      if (!this.userPwd2) {
        this.$toast('请输入确认密码')
        return
      }
      if (this.userPwd != this.userPwd2) {
        this.$toast('两次密码不一致')
        return
      }
      const res = await this.$HTTP.post(this, 'API_USERCENTER_USER_ANON_REGISTER', {
        appId: 'webhaojingchouh5',
        userType: this.userType,
        username: this.username,
        smsVerifyCode: this.smsVerifyCode,
        userPwd: this.userPwd
      }, {}, true)
      console.log('res.responseDescription', res);
      if (res.statusCode === 200) {
        this.$toast('注册成功');
        setTimeout(() => {
          this.goBack()
        }, 1000)
      }
    },
    //获取验证码
    async sendCode() {
      if (this.sendDisabled) {
        return;
      }
      if (!validator.isMobile(this.username, "手机号码格式有误")) {
        return
      }
      const res = await this.$HTTP.post(this, 'API_USERCENTER_LOGIN_SIGNUP_CODE', {
        phoneNumber: this.username,
        smsType: '1' // smsType 发短信类型 1注册 2登录
      }, {}, true)
      if (res.statusCode === 200) {
        this.smsVerifyCode = res.responseDescription
        this.$toast(`验证码已发送至您的手机${this.username}`);
        this.sendDisabled = true;
        window.setTimeLoginObj = window.setInterval(() => {
          if ((this.time--) <= 0) {
            this.time = 60;
            this.sendDisabled = false;
            clearInterval(window.setTimeLoginObj);
          }
        }, 1000);
      }
    }
  }
};
</script>

<style lang="less">
.login_page {
  .content {
    width: 90%;
    margin: 10px auto;
    //margin-top: 14px;
    .smsVerifyCode {
      width: 7.5em;
      display: block;
    }
  }
  .inputMessage{
    border-radius: 10px;
    margin-bottom: 10px;
    height: 50px;
    line-height: 30px;
  }
}
</style>
