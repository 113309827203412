import { Toast } from 'vant';

export default {
	/**
	 * 手机号码校验
	 * 		
	 */
	isMobile: function (numStr, errMsg) {
		if (typeof errMsg == "undefined") {
			errMsg = '你输入的手机号码格式不正确';
		}
		if (!/^(13|15|18|14|17|16|19)\d{9}$/.test(numStr)) {
			Toast(errMsg);
			return false;
		}
		return true;
	}
}